<template>
    <!--新增对话框-->
    <el-dialog
             title="编辑系统文字说明"
            :visible.sync="dialogVisible"
            width="1024px"

             :close-on-click-modal="false"
            :before-close="handleClose">

        <el-form :model="editForm" :rules="editFormRules" ref="editForm" label-width="100px" class="demo-editForm">

            <el-form-item label="标题" prop="title" label-width="100px" >
                <el-input v-model="editForm.title" autocomplete="off" :disabled="true"></el-input>
            </el-form-item>

            <el-form-item label="内容" label-width="100px" prop="describe" >
                <tinymce-editor v-model="editForm.describe" :type="3"  @change="change" ></tinymce-editor>
            </el-form-item>

            <el-form-item>
                <el-button type="primary" @click="submitForm('editForm')">立即创建</el-button>
                <el-button @click="resetForm('editForm')">重置</el-button>
            </el-form-item>
        </el-form>

    </el-dialog>
</template>

<script>
    import TinymceEditor from "../inc/TinymceEditor";
    export default {
        name: "UpdateCommonExplain",
        components:{
            TinymceEditor
        },
        data(){
            return{
                editForm:{},
                editFormRules: {
                    title: [
                        {required: true, message: '请输入标题', trigger: 'blur'}
                    ],
                    describe: [
                        {required: true, message: '请输入内容说明', trigger: 'blur'}
                    ]
                },
                dialogVisible: false,
            }
        },
        methods:{
            change(val) {
                this.editForm.describe=val;
            },
            resetForm(formName) {
                this.$refs[formName].resetFields();
                this.dialogVisible = false
                this.editForm = {}
            },
            handleClose() {
                this.resetForm('editForm')
            },
            submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    console.log("valid",valid)
                    if (valid) {
                        this.$axios.post('/admin/commonExplain/updateCommonExplain' , this.editForm)
                            .then(res => {
                                this.$message({
                                    showClose: true,
                                    message: '恭喜你，操作成功',
                                    type: 'success',
                                    onClose:() => {
                                        this.$emit('refreshDataList')
                                    }
                                });
                                this.dialogVisible = false
                                this.resetForm(formName)
                            })
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            init(typeCode){
                this.dialogVisible=true;
                if (typeCode!=null&&typeCode!=''){
                    this.$axios.post('/admin/commonExplain/getCommonExplainInfo', {typeCode:typeCode}).then(res => {
                        this.editForm = res.data
                    })
                }
            },
        }
    }
</script>

<style scoped>

</style>
